import { Timesheet } from "@app/models";
import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import {
  adapter,
  selectError,
  selectForm,
  selectId,
  selectLoading,
  selectSegmentBottom,
  selectSegmentTop,
  selectStatus,
} from "./dive-log-lines.reducer";

import sortBy from "array-sort-by";

export const selectDiveLogLines = (state: AppState) => state.diveLogLines;

export const {
  selectIds: selectDiveLogLineIds,
  selectEntities: selectDiveLogLineEntities,
  selectAll: selectAllDiveLogLines,
  selectTotal: selectDiveLogLinesTotal,
} = adapter.getSelectors(selectDiveLogLines);

export const selectDiveLogLinesStatus = createSelector(
  selectDiveLogLines,
  selectStatus
);

export const selectDiveLogLineError = createSelector(
  selectDiveLogLines,
  selectError
);

export const selectDiveLogLinesLoading = createSelector(
  selectDiveLogLines,
  selectLoading
);

export const selectDiveLogLineId = createSelector(selectDiveLogLines, selectId);

export const selectDiveLogLine = createSelector(
  selectDiveLogLineEntities,
  selectDiveLogLineId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectDiveLogLineForm = createSelector(
  selectDiveLogLines,
  selectForm
);

export const selectDiveLogLineSupervisorItems = createSelector(
  selectDiveLogLine,
  (diveLogLine) =>
    diveLogLine.item_ids.filter(
      (diveLogItem) => diveLogItem.group_id.state == "supervisor"
    )
);

export const selectDiveLogLinePreDiveItems = createSelector(
  selectDiveLogLine,
  (diveLogLine) =>
    diveLogLine.item_ids.filter(
      (diveLogItem) => diveLogItem.group_id.state == "pre_dive"
    )
);

export const selectDiveLogLineDiveItems = createSelector(
  selectDiveLogLine,
  (diveLogLine) =>
    diveLogLine.item_ids.filter(
      (diveLogItem) => diveLogItem.group_id.state == "dive"
    )
);

export const selectDiveLogLineActivities = createSelector(
  selectDiveLogLine,
  (diveLogLine) => {
    const activities: Timesheet[] = [];
    diveLogLine.item_ids
      .filter((logItem) => logItem.is_activity)
      .forEach((logItem) => {
        // TODO Diverius galime susidėti į value1Char?

        if (diveLogLine.diver_1_id && logItem.value_1_time) {
          activities.push(
            new Timesheet({
              name: `${diveLogLine.diver_1_id.name || "Diver 1"} ${
                logItem.name
              }`,
              time: logItem.value_1_time,
              description: logItem.value_1_text,
            })
          );
        }
        if (diveLogLine.diver_2_id && logItem.value_2_time) {
          activities.push(
            new Timesheet({
              name: `${diveLogLine.diver_2_id.name || "Diver 2"} ${
                logItem.name
              }`,
              time: logItem.value_2_time,
              description: logItem.value_2_text,
            })
          );
        }
        if (diveLogLine.standby_id && logItem.value_3_time) {
          activities.push(
            new Timesheet({
              name: `${diveLogLine.standby_id.name || "Diver 3"} ${
                logItem.name
              }`,
              time: logItem.value_3_time,
              description: logItem.value_3_text,
            })
          );
        }
      });
    return <Timesheet[]>(
      sortBy(
        [...diveLogLine.time_sheet_ids, ...activities],
        (item: Timesheet) => item.time
      )
    );
  }
);

export const selectDiveLogLineSegmentTop = createSelector(
  selectDiveLogLines,
  selectSegmentTop
);

export const selectDiveLogLineSegmentBottom = createSelector(
  selectDiveLogLines,
  selectSegmentBottom
);

export const selectDiveLogLineHideSupervisor = createSelector(
  selectDiveLogLineSegmentTop,
  (segment) => segment !== "supervisor"
);

export const selectDiveLogLineHidePreDive = createSelector(
  selectDiveLogLineSegmentTop,
  (segment) => segment != "pre_dive"
);

export const selectDiveLogLineHideDive = createSelector(
  selectDiveLogLineSegmentTop,
  (segment) => segment != "dive"
);

export const selectDiveLogLineHideActivities = createSelector(
  selectDiveLogLineSegmentTop,
  (segment) => segment != "activities"
);

export const selectDiveLogLineHideFooter = createSelector(
  selectDiveLogLineSegmentTop,
  (segment) => !(segment == "pre_dive" || segment == "dive")
);

export const selectDiveLogLineHideDiver1 = createSelector(
  selectDiveLogLineSegmentBottom,
  (segment) => !(segment == "diver1" || segment == "all")
);

export const selectDiveLogLineHideDiver2 = createSelector(
  selectDiveLogLineSegmentBottom,
  (segment) => !(segment == "diver2" || segment == "all")
);

export const selectDiveLogLineHideStandby = createSelector(
  selectDiveLogLineSegmentBottom,
  (segment) => !(segment == "standby" || segment == "all")
);

export const selectDiveLogLineCanEdit = createSelector(
  // TODO fix that
  selectDiveLogLines,
  (state) => true
);
